import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const LeistungsanforderungPage = () => {
  return (
    <Layout pageTitle="Leistungsanforderung" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Artikel - 20. Oktober 2022, Krankenhausinterne Kommunikation beschleunigen</p> 
                <h1>Digitale Prozesse ohne Medienbrüche</h1>
               
                <p className="text-medium">
                Digital optimierte Prozesse sind eine Chance für das Gesundheitssystem – für Patient:innen, Leistungserbringer und Versicherer. 
                Denn neue Technologien können die Effizienz und Qualität steigern. Eine <span className="text-blue">digitale Leistungsanforderung</span>, zum Beispiel, digitalisiert und 
                automatisiert den Prozess für alle patienten-bezogenen Aufträge, beschleunigt so die Kommunikation und senkt die Kosten von Spitälern (Krankenhäusern).
                                </p>

                <p>In Spitälern gibt es eine breite Fülle an Aufträgen und Anforderungen. Ein komplett digitales Auftragsmanagement deckt alle Schritte der Leistungsanforderung 
                eines Krankenhauses ab – ohne Medienbrüche durch Papier oder Telefon. Das bringt mehr Effizienz und Effektivität und senkt die Kosten.</p>

                <h3 style={{ marginBottom: 4, marginTop: 30 }} className="italic">«Seit der unternehmensweiten Einführung des digitalen 
                Auftragsmanagements ist es viel einfacher für alle Beteiligten Aufträge zu erteilen und den Überblick zu behalten. Dieser 
                hohe Digitalisierungsgrad macht die interne Kommunikation und Interaktion transparenter und nachvollziehbarer.»</h3>
                <div style={{ fontSize: 15, marginBottom: 60  }} className="italic">- Juri Toffol, Abteilungsleiter Medizinische Services vom Universitätsspital Basel</div>

                <h3>Patientenbezogene Aufträge zentral steuern</h3>
                <p>Das <a href="https://www.tie.ch/module/#auftragsmanagement" target="_blank" rel="noreferrer">digitale Auftragsmanagement</a>, Order Entry ist die Lösung für digitale Leistungsanforderung von the i-engineers und <a href="https://www.netcetera.com/home/Healthcare/Order-Entry.html" target="_blank" rel="noreferrer" >Netcetera</a>, optimiert krankenhausinterne Prozesse und beschleunigt die Kommunikationsprozesse.
                Krankenhäuser steuern damit sämtliche patienten-bezogene Aufträge zentral aus einem System. </p>

                <p style={{ marginBottom: 4 }} >Lesen Sie den ganzen Artikel im clinicum Magazin CH</p>
                <div className={buttonGroup}><a className="button-outline blue" href="https://www.clinicum.ch/images/getFile?t=ausgabe_artikel&f=dokument&id=2774" target="_blank" rel="noreferrer" >Zum Artikel</a></div>


              
              
              </div>
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default LeistungsanforderungPage;
